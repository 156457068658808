import React from "react"
import { connect as connectFela } from "react-fela"
import { graphql } from "gatsby"
import { combineRules } from "fela"

import AusSection from "components/aus-section"
import CaseStudyDownload from "./case-study-download"
import Column from "./column"
import Container from 'components/container'
import Content from "./content"
import H2 from "components/typography/H2"
import H4 from "components/typography/H4"
import Header from "./header"
import JumpMenu from "./jump-menu"
import Layout from "components/layout"
import P from "components/typography/P"
import Scroll from 'react-scroll'
import Share from "components/Share"
import fluidValues from "utilities/fluidValues"
import slugify from "utilities/slugify"
import { getHexColor } from "theme/colors"
import  QuickView from "./quick-view"

export default connectFela(({
  data: {
    sanityAusPage: {
      color,
    }
  }
}) => ({
  sections: {
    ...fluidValues({
      marginBottom: [90, 200],
    })
  },
  cols_section: {
    maxWidth: 1440,
    flexWrap: 'nowrap',
    ...fluidValues({
      marginBottom: [60, 200],
    }),
    columnGap: 30,
  },
  cols2_section: {
    maxWidth: 1440,
    flexWrap: 'nowrap',
    ...fluidValues({
      marginBottom: [60, 200],
      rowGap: [57, 120]
    }),
    columnGap: 30,
  },
  aus_section: {
    maxWidth: 1440,
    marginLeft: 'auto',
    marginRight: 'auto',
    ...fluidValues({
      marginBottom: [48, 60]
    })
  },
  aus_section_simple: {
    maxWidth: 1440,
    marginLeft: 'auto',
    marginRight: 'auto',
    ...fluidValues({
      marginBottom: [60, 200],
    })
  },
  content_section: {
    maxWidth: 1440,
    ...fluidValues({
      marginBottom: [60, 200],
    }),
  },
  smallMarginBottom: {
    ...fluidValues({
      marginBottom: [32, 60],
    }),
  },
  noMarginBottom: {
    marginBottom: 0
  },
  jump_menu: {
    ...fluidValues({
      marginBottom: [60, 120]
    })
  },
  quick_view: {
    ...fluidValues({
      marginBottom: [60, 120]
    })
  }
}))(({
  styles,
  rules,
  path,
  pageContext,
  data: {
    jobs,
    settings,
    sanityAusPage: {
      title,
      meta,
      slug,
      color,
      header,
      sections
    }
  }
}) => {
  return <Layout
           pageColor={getHexColor(color, '400')}
           linkColor={getHexColor(color, '200')}
           title={title}
           slug={{ current: path }}
           meta={meta || {}}
           settings={settings}
           jobs={jobs}
           country={pageContext.country}
           pageContext={pageContext}
         >
           <Header
             category={header.category}
             title={header.title}
             copy={header.copy}
             extend={{styles: rules.header}}
             hidesidebar={false}
             bgColor={getHexColor("Canvas", '400')}
             image={header.image}
             color={header.fontcolor !== 'Light' ? getHexColor('Console', '500') : getHexColor('Canvas', '500')}
           />

           <Container tag={'section'} noPadding extend={{styles: rules.sections}}>
             {sections.length> 0 && sections.map((section, index) => {
               if (section._type === 'jump_menu') {
                 return <JumpMenu
                          sections={sections}
                          title={section.title}
                          color={color}
                          pageContext={pageContext}
                          share={section.share}
                          key={index + "jm"}
                          extend={{
                            container: (sections[index + 1]._type === 'quick_view' ? rules.noMarginBottom : rules.jump_menu),
                          }}
                        />
               }

               if (section._type === 'quick_view') {
                 return <QuickView
                          {...section}
                          color={color}
                          key={index + "qv"}
                          pageContext={pageContext}
                          extend={{container: rules.quick_view}}
                        />
               }

               if (section._type === 'case_study_download') {
                 return <Container flex extend={{styles: rules.cols_section}} key={index + "cs"}>
                          <CaseStudyDownload
                            title={section.title}
                            strapline={section.strapline}
                            intro={section.intro}
                            button_label={section.button_label}
                            case_study={section.case_study}
                            link={section.link}
                            image={section.image}
                            extend={{styles: rules.case_study_download}}
                          />
                        </Container>
               }

               if (section._type === 'aus_section') {
                 return <div className={(sections[index + 1]?._type == 'aus_section' ? styles.aus_section_simple : styles.aus_section)} key={index + "as"}>
                          <AusSection
                            title={section.title}
                            strapline={section.strapline}
                            intro={section.intro}
                            image={section.image}
                            showBlackBar={section.showBlackBar}
                            links={section.links}
                          />
                        </div>
               }

               if (section._type === 'aus_content') {
                 return <div className={styles.content_section + " " + (
                               sections[index + 1]._type === 'aus_two_columns' ?
                                 styles.smallMarginBottom :
                                 ''
                             )} key={index + "c"}><Content content={section.content} /></div>
               }

               if (section._type === 'aus_two_columns') {
                 return section.col1[0]._type === 'aus_content' ?
                   <Column
                     contents={[section.col1[0].content, section.col2[0]?.content]}
                     extend={{styles: combineRules(rules.cols_section, sections[index + 1]?._type === 'aus_two_columns' && sections[index + 1]?.col1[0]._type === 'aus_content' ? rules.smallMarginBottom : [])}}
                     key={index + "col"} />
                 :
                 <Container flex extend={{styles: rules.cols2_section}} key={index + "con"} >
                   <CaseStudyDownload
                     title={section.col1[0].title}
                     strapline={section.col1[0].strapline}
                     intro={section.col1[0].intro}
                     button_label={section.col1[0].button_label}
                     case_study={section.col1[0].case_study}
                     link={section.col1[0].link}
                     image={section.col1[0].image}
                   />
                   <CaseStudyDownload
                     title={section.col2[0].title}
                     strapline={section.col2[0].strapline}
                     intro={section.col2[0].intro}
                     button_label={section.col2[0].button_label}
                     case_study={section.col2[0].case_study}
                     link={section.col2[0].link}
                     image={section.col2[0].image}
                   />
                 </Container>
               }

               return null
             })}
           </Container>
         </Layout>
})

export const query = graphql`
query($country: String!, $slug: String!) {
    settings: sanitySettings(countrySet: { eq: $country }) {
        ...Settings
    }

    jobs: allSanityJob(filter: { countrySet: { eq: $country } }) {
        edges {
            node {
                ...Jobs
            }
        }
    }

    sanityAusPage(countrySet: { eq: $country }, slug: { current: { eq: $slug }}) {
      title
      meta {
        title
        description
        other {
          property
          content
        }
      }
      slug {
        current
      }
      color
      header {
        title
        category
        fontcolor
        copy: _rawCopy
        image {
          credits
          asset {
            gatsbyImageData(layout: CONSTRAINED, width:  1440)
          }
        }
      }

      sections {
        ...on SanityJumpMenu {
          ...jumpMenuFrag
        }
        ...on SanityQuickView {
          ...quickViewFrag
        }
        ...on SanityCaseStudyDownload {
          ...caseStudyDownloadFrag
        }
        ...on SanityAusSection {
          ...ausSectionFrag
        }
        ...on SanityAusContent {
          ...ausContentFrag
        }
        ... on SanityAusTwoColumns {
          _type
          col1 {
            ... on SanityAusContent {
                ...ausContentFrag
            }
            ... on SanityCaseStudyDownload {
                ...caseStudyDownloadFrag
            }
          }
          col2 {
            ... on SanityAusContent {
                ...ausContentFrag
            }
            ... on SanityCaseStudyDownload {
                ...caseStudyDownloadFrag
            }
          }
       }
    }
  }
}
`
