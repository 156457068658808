import React from "react"
import { connect as connectFela } from "react-fela"
import BlockContent from '@sanity/block-content-to-react'
import { graphql } from "gatsby"

import Container from "components/container"
import H4 from "components/typography/H4"
import Link from "components/Link"
import P from "components/typography/P"
import Ul from "components/typography/UL"
import calc from "utilities/calc"
import colors from "theme/colors"
import fluidValues from "utilities/fluidValues"
import { commonMarks } from "utilities/serializers"
import { serializers as linkSerializers } from "components/BaseLink.js"
import * as playbookUrl from "commands/playbookUrl"

const serializers = ({styles, rules}) => {
  return {
    list: props => ( <Ul {...props} type={props.type} extend={{styles: rules.list}}>{props.children}</Ul> ),
    listItem: props => {
      return <P tag={'li'} extend={{styles: rules.listItem}} version={"list"}>{props.children}</P>
    },
    block: (props) => {
      const style = props.node.style

      if (style === 'title') {
        return <H4 extend={{styles: rules.paragraphTitle}}>{props.children}</H4>
      }

      if (style === 'large') {
        return <P version={'large'} extend={{styles: rules.large}}>{props.children}</P>
      }

      return <P version={'body'} extend={{styles: rules.large}}>{props.children}</P>
    },
    marks: {
      ...linkSerializers(styles, rules),
    ...commonMarks({styles, rules}),
    }
  }
}

/* Component */
export default connectFela(() => ({
  container: {
    display: 'flex'
  },
  fakeLeft: {
    width: calc(20, 100, 320, 768),
    tabletPortraitAndUp: {
      width: calc(100, 192, 768, 1440),
    },
  },
  contentContainer: {
    ...fluidValues({
      marginLeft: [20, 110],
    }),
  },
  copyContainer: {
    width: calc(257, 507, 320, 768),
    tabletPortraitAndUp: {
      width: calc(507, 840, 768, 1440),
    },
    maxWidth: 840,
    '& > :first-child': {
      marginTop: '0px !important'
    }
  },
  list: {
    listStyleType: 'disc',
    marginBottom: '1rem',
    ...fluidValues({
      marginLeft: [24, 34]
    })
  },
  listItem: {
    '@media (min-width: 320px)': {
      paddingLeft: '0px !important',
      marginBottom: 0
    }
  },
  paragraphTitle: {
    color: colors.colorConsole500
  },
  large: {
    color: colors.colorConsole500
  },
  link: {
    color: colors.colorConsole500
  }
}))(({
  styles,
  rules,
  content,
}) => {
  return <Container noPadding extend={{styles: rules.container}}>
           <div className={styles.fakeLeft}>&nbsp;</div>
           <div className={styles.contentContainer}>
           <BlockContent
             renderContainerOnSingleChild
             className={styles.copyContainer}
             blocks={content}
             serializers={serializers({rules, styles})} />
           </div>
         </Container>
})

export const ausContentFrag = graphql`
fragment ausContentFrag on SanityAusContent {
     _type
     content: _rawContent(resolveReferences: {maxDepth: 10})
}`
