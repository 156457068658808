import React from "react";
import { connect as connectFela } from "react-fela";
import { GatsbyImage as Img } from "gatsby-plugin-image";
import { graphql } from "gatsby";

import Container from "components/container";
import H2 from "components/typography/H2";
import P from "components/typography/P";
import calc from "utilities/calc";
import colors from "theme/colors";
import fluidValues from "utilities/fluidValues";
import slugify from "utilities/slugify";
import Link from "components/Link";

/* Component */
export default connectFela(({ image, showBlackBar }) => ({
  container: {
    flexFlow: "column nowrap !important",
    ...fluidValues({
      minHeight: [168, 702],
    }),
  },
  artefactContainer: {
    width: "100%",
    extend: {
      condition: !showBlackBar,
      style: {
        visibility: 'hidden'
      }
    }
  },
  topArtefact: {
    ...fluidValues({
      height: [12, 24],
      marginTop: [91, 152],
    }),
    width: calc(43, 100, 320, 768),
    tabletPortraitAndUp: {
      width: calc(100, 192, 768, 1440),
    },
    zIndex: 1,
    backgroundColor: colors.colorConsole500,
  },
  image: {
    position: "absolute",
    top: 0,
    right: 0,
    zIndex: 0,
    width: "72%",
    ...fluidValues({
      paddingLeft: [67, 408],
      height: [168, 702],
    }),
  },
  bold: {
    color: colors.colorConsole500,
    fontWeight: 600,
  },
  copyContainer: {
    position: "relative",
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    maxWidth: 450,
    width: calc(233, 450, 320, 768),
    tabletPortraitAndUp: {
      width: calc(450, 405, 768, 1440),
    },
    ...fluidValues({
      marginLeft: [20, 192],
      marginTop: [48, 90],
    }),
  },
  strapline: {
    color: colors.colorConsole500,
    ...fluidValues({
      maxWidth: [233, 405],
    }),
  },
  title: {
    color: colors.colorConsole500,
  },
  link: {
    color: colors.colorConsole500,
    marginBottom: '1rem'
  },
}))(({
  styles,
  rules,
  title,
  strapline,
  intro,
  image,
  links,
  showBlackBar,
}) => {
  return (
    <Container
      flex
      noPadding
      extend={{ styles: rules.container }}
      id={slugify(title)}
    >
      <div className={styles.artefactContainer}>
        <div className={styles.topArtefact}></div>
      </div>
      <div className={styles.copyContainer}>
        <H2 neo extend={{ styles: rules.title }}>
          {title}
        </H2>
        <P version={"strapline"} extend={{ styles: rules.strapline }}>
          {strapline}
        </P>
        <P version={"medium"} extend={{ styles: rules.bold }}>
          {intro}
        </P>

        {links && links.map((link) => (
          <Link
            key={link._key}
            to={link}
            color={"white"}
            local={true}
            bgColor={colors.colorConsole500}
            extend={{ styles: rules.link }}
          >
            {link.label}
          </Link>
        ))}
      </div>
      {image && (
        <div className={styles.image}>
          <Img image={image.asset.gatsbyImageData} alt={title} />
        </div>
      )}
    </Container>
  );
});

export const ausSectionFrag = graphql`
  fragment ausSectionFrag on SanityAusSection {
    _type
    title
    strapline
    intro
    appearInMenu
    showBlackBar
    image {
      asset {
        gatsbyImageData(layout: CONSTRAINED, width: 1440)
      }
    }
    links {
      ...sanityLinkFrag
    }
  }
`;
