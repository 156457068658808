import React from "react"
import { connect as connectFela } from "react-fela"
import { graphql } from "gatsby"

import Container from "components/container"
import fluidValues from "utilities/fluidValues"
import calc from "utilities/calc"
import Content from "./content"

/* Component */
export default connectFela(() => ({
  contentContainer: {
    display: "flex",
    ...fluidValues({
      width: [257, 840],
      marginLeft: [20, 110],
    }),
    gap: 30,
    flexWrap: "wrap",
    "@media(min-width: 769px)": {
      flexWrap: "nowrap",
    },
  },
  col1: {
    margin: 0,
    flex: "0 1 auto",
    "@media(min-width: 769px)": {
      maxWidth: "50%",
    },
  },
  col2: {
    margin: 0,
    flex: "0 1 auto",
    "@media(min-width: 769px)": {
      maxWidth: "50%",
    },
  },
  fakeLeft: {
    width: calc(20, 100, 320, 768),
    tabletPortraitAndUp: {
      width: calc(100, 192, 768, 1440),
    },
  },
  disableColFakeLeft: {
    display: "none",
  },
  disableCopyContainer: {
    width: "100% !important",
  },
  disableMargin: {
    marginLeft: "0px !important",
  },
}))(({ styles, rules, contents }) => {
  return (
    <Container flex noPadding extend={{ styles: rules.styles }}>
      <div className={styles.fakeLeft}>&nbsp;</div>
      <div className={styles.contentContainer}>
        <Content
          extend={{
            container: rules.col1,
            fakeLeft: rules.disableColFakeLeft,
            copyContainer: rules.disableCopyContainer,
            contentContainer: rules.disableMargin,
          }}
          key={1}
          content={contents[0]}
        />
        {contents[1] && (
          <Content
            extend={{
              container: rules.col2,
              fakeLeft: rules.disableColFakeLeft,
              copyContainer: rules.disableCopyContainer,
              contentContainer: rules.disableMargin,
            }}
            key={2}
            content={contents[1]}
          />
        )}
      </div>
    </Container>
  )
})

// export const quoteContentFrag = graphql`
// fragment sanityQuotedContentFrag on SanityQuotedContent {
//     _type
//     _rawContent
//     name
//     position
//     company
// }`
