import React, { Fragment } from "react"
import { connect as connectFela } from "react-fela"
import { graphql } from "gatsby"

import AusSection from "components/aus-section"
import Container from 'components/container'
import H2 from "components/typography/H2"
import H4 from "components/typography/H4"
import P from "components/typography/P"
import Scroll from 'react-scroll'
import Share from "components/Share"
import { getHexColor } from "theme/colors"
import fluidValues from "utilities/fluidValues"
import slugify from "utilities/slugify"

const isCurrentSection = (item) => typeof window !== 'undefined' && window.location.href.includes(slugify(item))
const scrollTo = (anchor) => Scroll.scroller.scrollTo(anchor, {smooth: true,})
const navActive = {
  '@media (min-width: 1000px)': {
    onAfter: {
      position: 'absolute',
      content: "''",
      backgroundColor: 'black',
      display: 'block',
      width: '110%',
      height: 11,
      bottom: -11,
    }
  }
}

const navActiveMobile = {
  '@media (max-width: 1000px)': {
    onAfter: {
      position: 'absolute',
      content: "''",
      backgroundColor: 'black',
      display: 'block',
      ...fluidValues({
        left: [-43, -110],
      }),
      width: 10,
      height: '100%',
    }
  }
}

export default connectFela(({ color, share }) => ({
  shareContainer: {
    backgroundColor: getHexColor(color, '400'),
    justifyContent: 'end',
    alignItems: 'center',
    ...fluidValues({
      height: [72, 136],
    })
  },
  social: {
    ...fluidValues({
      marginLeft: [6, 12]
    }),
  },
  currentSection: {
    ...navActive
  },
  currentSectionMobile: {
    ...navActiveMobile
  },
  navContainer: {
    backgroundColor: getHexColor(color, '400'),
    phoneOnly: {
      paddingLeft: '0 !important',
      paddingRight: '0 !important',
    },
    extend: {
      condition: !share,
      style: {
        ...fluidValues({
          paddingBottom: [32, 60],
        })
      }
    }
  },
  navInnerContainer: {
    boxSizing: 'border-box',
    maxWidth: 1129,
    backgroundColor: 'white',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'noWrap',
    justifyContent: 'center',
    marginLeft: 'auto',
    marginRight: 'auto',
    ...fluidValues({
      paddingTop: [32, 85],
      paddingLeft: [43, 110],
      paddingRight: [43, 110],
      paddingBottom: [43, 134],
    })
  },
  navHeaders: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    '& > h2:last-child': {
      textAlign: 'left',
      maxWidth: 329,
      width: '100%',
      '@media (max-width: 1000px)': {
        display: 'none',
      }
    }
  },
  navRows: {
    display: 'flex',
    flexDirection: 'column'
  },
  navRow: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    minHeight: 77,
    flexWrap: 'wrap',
    borderBottom: '1px solid black',
    '@media (max-width: 1000px)': {
      borderBottom: 'none',
      marginBottom: 21
    },
    '& > h4': {
      marginTop: '0 !important',
      marginBottom: '0 !important',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      position: 'relative',
      onHover: {
        ...navActive
      },
      '@media (max-width: 999px)': {
        width: '100%'
      },
    },
    '& > p': {
      marginTop: '0 !important',
      marginBottom: '0 !important',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      maxWidth: 329,
      textAlign: 'left',
      '@media (max-width: 1000px)': {
        maxWidth: '100%'
      },
    },
    justifyContent: 'space-between',
  },
}))(({
  styles,
  rules,
  title,
  sections,
  pageContext,
  color,
  share
}) => {
  return <div className={styles.container}>
    {sections.length > 0 &&
     <Container tag={'section'} extend={{styles: rules.navContainer}}>
       <div className={styles.navInnerContainer}>
         <div className={styles.navHeaders}>
           <H2>{title}</H2>
           <H2>...helps you</H2>
         </div>

         <div className={styles.navRows}>
           {sections.map((section, index) => {
             if (section._type === 'aus_section' && section.appearInMenu) {
               return <div className={styles.navRow + " " +
                                      (
                                        isCurrentSection(section.title) ?
                                          styles.currentSectionMobile :
                                          ''
                                      )
                           } key={'section' + index}>
                        <H4 extend={{styles: isCurrentSection(section.title) ? rules.currentSection : ''}}>
                          <a href={"#" + slugify(section.title)} onClick={() => scrollTo(slugify(section.title))}>{section.title}</a>
                        </H4>
                        <P version={'medium'}>{section.strapline}</P>
                      </div>
             }
             return null
           })}
         </div>
       </div>
     </Container>}

           {share &&
            <Container flex tag={'section'} extend={{styles: rules.shareContainer}}>
              <Share
                extend={{social: rules.social}}
                bgColor={getHexColor(color, '400')}
                url={typeof window !== 'undefined' && window.location.href}
                country={pageContext.country}
              />
            </Container>}
  </div>
})

export const jumpMenuFrag = graphql`
fragment jumpMenuFrag on SanityJumpMenu {
     _type
     title
     share
}`
