import React, { useState } from "react"
import { connect as connectFela } from "react-fela"
import { GatsbyImage as Img } from 'gatsby-plugin-image'
import { graphql } from "gatsby"

import Button from "components/Button"
import Container from "components/container"
import H2 from "components/typography/H2"
import P from "components/typography/P"
import calc from "utilities/calc"
import colors from "theme/colors"
import fluidValues from "utilities/fluidValues"
import slugify from "utilities/slugify"
import * as playbookUrl from "commands/playbookUrl"

/* Component */
export default connectFela(({ image }) => ({
  container: {
    maxWidth: 515,
    flexFlow: 'column nowrap !important',
    ...fluidValues({
      minHeight: [168, 702],
    })
  },
  artefactContainer: {
    width: '100%'
  },
  topArtefact: {
    position: 'absolute',
    bottom: 0,
    left: 0,
    ...fluidValues({
      height: [12, 24],
      width: [93, 189]
    }),
    zIndex: 1,
    backgroundColor: colors.colorConsole500,
  },
  image: {
    position: 'relative',
    zIndex: 0,
    width: '100%',
  },
  bold: {
    color: colors.colorConsole500,
    fontWeight: 600
  },
  copyContainer: {
    position: 'relative',
    zIndex: 1,
    maxWidth: 450,
    width: calc(233, 450, 320, 768),
    tabletPortraitAndUp: {
      width: calc(450, 405, 768, 1440),
    },
    ...fluidValues({
      marginTop: [14, 48],
      marginBottom: [8, 16]
    }),
  },
  title: {
    color: colors.colorConsole500,
    ...fluidValues({
      marginBottom: [12, 24]
    })
  },
  button: {
    width: '100%',
    maxWidth: 404,
    marginBottom: 'auto',
    boxSizing: 'border-box',

    tabletLandscapeAndUp: {
      marginRight: calc(80, 143, 1200, 1440),
    },
  },
}))(({
  styles,
  rules,
  title,
  intro,
  image,
  button_label,
  link,
  case_study
}) => {
  return <Container flex noPadding extend={{styles: rules.container}} id={slugify(title)}>
           {image && <div className={styles.image}>
                       <Img image={image.asset.gatsbyImageData} alt={title} />
                       <div className={styles.artefactContainer}><div className={styles.topArtefact}></div></div>
                     </div>}
             <div className={styles.copyContainer}>
               <H2 neo extend={{styles: rules.title}}>{title}</H2>
               <P version={"medium"} extend={{styles: rules.bold}}>{intro}</P>
             </div>

           {(link || case_study) && <Button
                                      extend={{styles: rules.button}}
                                      download={case_study ? playbookUrl.extractFilename(case_study.file) : ""}
                                      to={case_study ? playbookUrl.buildUrl(case_study.file) : ""}>
                                      {button_label}
                                    </Button>}
         </Container>
})

export const caseStudyDownloadFrag = graphql`
fragment caseStudyDownloadFrag on SanityCaseStudyDownload {
     _type
     title
     intro
     button_label
     link {
       ...sanityLinkFrag
     }
     image {
       asset {
         gatsbyImageData(layout: CONSTRAINED, width:  1440)
       }
     }
     case_study {
       title
       file {
         asset {
           localFile {
             publicURL
             name
             internal {
               contentDigest
             }
           }
           originalFilename
         }
       }
     }
}`
